<template>
	<div class="sort">
		<label>
			<span>Sortieren</span>
			<select
				v-model="selected"
				@change="_onChange"
			>
				<option
					v-for="{text, value} in options"
					:key="value"
					:value="value"
				>
					{{ text }}
				</option>
			</select>
		</label>
	</div>
</template>

<script>
export default {
	name: 'AppSort',
	data() {
		return {
			selected: 'name',
			options: [
				{
					text: 'von A-Z',
					value: 'name'
				},
				{
					text: 'nach Zeit',
					value: 'start'
				},
				{
					text: 'nach Größe',
					value: 'size'
				},
				{
					text: 'nach Erstbeschreibung',
					value: 'year'
				}
			]
		};
	},
	methods: {
		_onChange() {
			this.$store.commit('sortAnimals', this.selected);
		}
	}
};
</script>

<style lang="scss">
	.sort {
		text-align: right;

		label {
			display: inline-block;
			position: relative;
		}

		span {
			@include button();
			@include button-secondary();
			pointer-events: none;
			position: relative;
			z-index: 2;
			padding-right: 2.5rem;
			background-color: $color-white;

			&::after {
				transition: transform $transition-duration ease-out;
				content: '';
				pointer-events: none;
				transform: translate(0, -50%) rotate(45deg);
				position: absolute;
				top: 50%;
				right: 20px;
				z-index: 0;
				margin-top: -2px;
				padding: 3px;
				border-width: 0 2px 2px 0;
				border-style: solid;
			}
		}

		select {
			@include button();
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			border: 0;
			text-overflow: ellipsis;
			text-transform: none;
		}
	}
</style>
