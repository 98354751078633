<template>
	<header class="header">
		<a
			class="logo"
			href="./"
			title="Scharfzahn"
		>
			<BaseIcon src="ui/logo.svg" />
		</a>

		<div class="menu-button">
			<BaseButton
				class="button-primary filter"
				:click="_onToggleMenu"
			>
				Filter{{ hasFilters ? ' (' + hasFilters + ')' : '' }}
			</BaseButton>
			<FilterButtonReset />
		</div>

		<div class="menu-sort">
			<AppSort />
		</div>
	</header>
</template>

<script>
import AppSort from './AppSort';
import {EventBus} from '../event-bus';
import {mapGetters} from 'vuex';


export default {
	name: 'AppHeader',
	components: {AppSort},
	computed: mapGetters(['hasFilters']),
	methods: {
		_onToggleMenu() {
			EventBus.$emit('AppMenu:toggle');
		}
	}
};
</script>

<style lang="scss">
	@import '../scss/components/header';
	@import '../scss/components/logo';
</style>
